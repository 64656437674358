.contact-info-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #f8f9fa;
    font-family: "Open Sans", sans-serif;
    position: relative;
    z-index: 10;
  }
  
  .infoo-box {
    text-align: center;
    background-color: #36c6d9;
    flex: 1;
    border-radius: 0;
    margin: 0;
    height: 30vh;
    justify-content: center;
    text-align: center;
  }
  
  .infoo-box .info-icon {
    font-size: 30px;
    margin-bottom: 10px;
    
  }
  .infoo-box:nth-child(even) {
    background-color: #36c6d9;
  }
  
  .infoo-box:nth-child(odd) {
    background-color: #11282b;
    color: #36c6d9;
  }
  .info-content, .info-icon{
    margin-top: 20px;
  }
  
  .infoo-box .info-content h5 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .infoo-box .info-content p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }
  
  @media (max-width: 768px) {
    .contact-info-container {
      flex-direction: column;
    }
    .infoo-box {
      margin: 0;
      position: relative;
      overflow: visible;
    }
    .info-content, .info-icon{
      margin-bottom: 20px;
    } 
  }
  @media screen and (min-width: 770px) and (max-width: 1080px) {
  
  }
  