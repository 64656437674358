.why-choose-us {
  text-align: center;
  padding: 20px;
  
}

.why-choose-us h2 {
  color: #36c6d9;
  font-size: 40px;
  line-height: 48px;
  font-weight: 800;
  margin-bottom: 10px;
  font-family: 'poppins' sans-serif;
}

.why-choose-us p {
  margin-bottom: 20px;
  color: #666;
  font-size: 16px;
  line-height: 26px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  text-align: center;
}

.card {
  position: relative;
  width: calc(33.333% - 20px);
  padding: 20px;
  min-height: 250px; /* Set a minimum height for the cards */
  color: #36c6d9 !important;
  border-radius: 10px;
  background-color: transparent !important;
  background: url('../../image/lh.png') no-repeat center center/cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
  text-align: center;
  justify-content: center;
}
.card p{
  color: #36c6d9 !important;
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  
}
.card h3{
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
}
.card:hover {
  transform: translateY(-10px);
  background-color: #11282B !important;

}

.card-content {
  margin-bottom: 10px;
}

.card-number {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #5b5757;
}

@media (max-width: 768px) {
  .card {
    width: calc(100% - 10px);
    margin: 0 20px;
  }
}

@media (max-width: 480px) {
  .card {
    width: 100%;
    margin: 0 10px;
  }
}
