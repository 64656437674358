.blog-page {
  text-align: center;
}

.heaader {
  padding: 20px;
}

.heaader h1 {
  font-size: 40px;
  color: #36c6d9;
  margin: 0;
  font-weight: 800;
  line-height: 48px;
}

.blog-list {
  display: flex;
  overflow: hidden;
  justify-content: center;
  padding: 20px;
}

/* .blog-card {
  width: 300px; /* Adjust the width as needed */
  /* margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
} */

.blog-card:hover {
  transform: scale(1.05);
}

.blog-image {
  width: 100%;
  height: auto;
}

.blog-content {
  padding: 15px;
}

.blog-date {
  font-size: 0.9em;
  color: #777;
}

.blog-title {
  font-size: 1.5em;
  color: #333;
  margin: 10px 0;
}

.blog-description {
  font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  color: #555;
}

@media (max-width: 768px) {
  .blog-list {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .blog-card {
    width: 100%;
    margin: 10px 5px;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-self: center;

  }
}

@media (max-width: 480px) {
  .blog-card {
    width: 100%;
    margin: 10px 5px;
  

  }
}