/* Basic Styles */
.locations-container {
    display: flex;
    justify-content: space-around;
    background-color: #36c6d9; /* Dark blue background */
    padding: 20px;
    color: white;
    text-align: center;
}

.location-item {
    flex: 1;
    margin: 0 10px;
    padding: 0 20px;
    position: relative;
    border-right: 2px solid rgba(255, 255, 255, 0.5); /* Add a more visible border */
}
.location-item:last-child{
    border-right: none;
}

.location-item h2 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 10px;
}

.location-item p {
    margin: 5px 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}
/* Add Vertical Border */
.location-item:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.5); /* Light border color */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .locations-container {
        flex-direction: column;
        align-items: center;
    }

    .location-item {
        margin-bottom: 20px;
        padding: 0;
        border-right: none;
    }

    .location-item:not(:last-child)::after {
        display: none; /* Hide the border on small screens */
    }
}