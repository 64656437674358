.testimonial-section {
  text-align: center;
  padding: 50px 20px;
  font-family: "Open Sans", sans-serif;
}

.testimonial-section h2 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 800;
  margin-bottom: 50px;
  color: #36c6d9;
}

.testimonial-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.testimonial-card {
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  width: 30%;
  box-sizing: border-box;
  margin: 10px 0;
  text-align: center;
  transition: transform 0.3s;
}

.testimonial-card:hover {
  transform: scale(1.05);
}

.client-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.profession {
  color: #777;
  font-style: italic;
}

.testimonial {
  color: #555;
  font-size: 16px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .testimonial-card {
    width: 80%;
    margin: 10px auto;
  }
}

@media (max-width: 480px) {
  .testimonial-card {
    width: 100%;
    margin: 10px 0;
  }

  .testimonial-container {
    flex-direction: column;
    align-items: center;
  }
}

.stars {
  color: #f1c40f;
  margin-top: 10px;
}

.stars .fa-star {
  margin-right: 5px;
}
