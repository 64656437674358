body {
  margin: 0;
}

.header {
  background-color: #b5dce3;
  height: 100vh;
  font-family: "karla", sans-serif;
  position: relative;
  z-index: 10;
  
}

#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: transparent;
  position: relative;
  margin-top: 0px;
  padding-top: 0px;
  
}

.headeer {
  display: none;
}

.header .logo img {
  width: 100px;
  margin-top: 20px;
}

.nav {
  display: flex;
  align-items: center;
  
}
.logo {
  margin-left: 40px;
}

.nav ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0; 
}

.nav ul li {
  margin: 0 15px;
  margin-top: 5px;
}
.nav ul li a:hover {
  background-color: #11282b;
  color: #5bc1cf !important;
  height: 80px;
  width: 80px;
}
.nav ul li a,
.nav ul li .book-online {
  text-decoration: none;
  color: #000;
  font-weight: bold;
}

.book-online {
  background-color: #11282b;
  border: none; 
  color: white !important;
  padding: 10px 20px;
  cursor: pointer;
}

.nav-toggle {
  display: none;
  font-size: 30px;
  background: none;
  border: none;
  cursor: pointer;
}

/* Banner Styles */
#banner {
  height: 70vh; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  background-color: #b5dce3;
  padding: 50px;
  position: relative;
  overflow: visible; 
}

#banner .content {
  max-width: 50%;

}

#banner h2 {
  font-size: 48px;
  font-weight: 800;
  line-height: 50px;
  margin-bottom: 20px;
  color: #11282b;
  z-index: 1; 
}

#banner p {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
  margin-bottom: 20px;
  color: #11282b;
}

#banner .cta-button {
  background-color: #ff7e36;
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
}

#banner .image-container {
  position: absolute; 
  right: -10%; 
  bottom: 0;
  width: 60%;
  z-index: 0; 
  max-width: 100%;
  height: 80%; 
  display: flex;
  align-items: center;
  overflow: visible;
  
}

#banner .image-container img {
  width: 100%; 
  height: 700px; 
  object-fit: cover; 
  object-position: center; 
  border-radius: 10px;
  margin-top: 60px;
}

/* Media queries for responsiveness */
@media screen and  (max-width: 768px) {
  .header {
    height: 100vh;
    background: url('../../image/HP2.png') no-repeat center center/cover;;
    background-color: #b5dce3;
  }

  .headerr {
    display: none;
  }

  .headeer {
    display: block;

  }

  #headeer {
    padding: 0px 0;
    position: absolute;
    margin-bottom: 0;
    width: 100%;
    z-index: 1000;
  }

  #headeer h1 a {
    color: white;
    font-size: 2.5em;
    text-transform: uppercase;
  }

  #headeer .logo img {
    height: 60px;
    width: 70px;
    margin-left: 10px;
    margin-top: 5px;
  }

  #nav {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  #nav ul {
    display: flex;
    align-items: center;
    list-style-type: none;
  }

  #nav li.special {
    position: relative;
  }

  .menuToggle {
    color: #11282b;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    }

  .menuToggle span {
    border: 2px solid #11282b;
    padding: 10px 20px;
    border-radius: 5px;
  }

  #menu {
    background: #5bc1cf;
    height: 100vh;
    width: 50%;
    position: fixed;
    top: 0;
    right: -400px;
    transition: right 0.3s ease-in-out;
    padding: 50px 20px;
    z-index: 1001;
    text-decoration: none;
    list-style-type: none;
  }

  #menu.open {
    right: 0;
  }

  #menu ul {
    flex-direction: column;
    list-style-type: none;
  }

  #menu ul li {
    margin: 20px 0;
    padding: 10px 0;
    border-bottom: 1px solid #7f91a6;
  }

  #menu ul li a {
    color: white;
    font-size: 1.5em;
    text-decoration: none;
  }

  #menu .close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2em;
    color: white;
    cursor: pointer;
  }

  #banner {
    height: 60vh;
    flex-direction: column;
    padding: 40px;
    background-color: transparent;
   
  }

  #banner .content {
    max-width: 100%;
    text-align: center;
    margin-top: 200px;
    z-index: 1; 
  }

  #banner .image-container {
    display: none; 
   }
 
   #banner .image-container img {
    display: none;
   }

  #banner h2 {
    font-size: 40px;
    line-height: 45px;
  }

  #banner p {
    font-size: 20px;
    line-height: 25px;
    font-weight: 500;
  }
}

@media screen and (min-width: 770px) and (max-width: 1080px) {
  .header{
    background: url('../../image/HP2.png');
    background-color: #b5dce3;
  }
  /* Banner Styles */
  #banner {
    height: 70vh; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    background-color: transparent;
    padding: 50px;
    position: relative; 
    overflow: visible; 
  }

  #banner .content {
    max-width: 50%;
    z-index: 1; 
  }

  #banner h2 {
    font-size: 35px;
    font-weight: 800;
    line-height: 40px;
    margin-bottom: 20px;
    color: #11282b;
  }

  #banner p {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
    margin-bottom: 20px;
    color: #11282b;
  }

  #banner .cta-button {
    background-color: #ff7e36;
    color: white;
    padding: 15px 30px;
    font-size: 18px;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  #banner .image-container {
   display: none; 
  }

  #banner .image-container img {
   display: none;
  }
}
@media screen and (min-width: 1400px) {

  #banner .image-container {
    position: absolute; 
    right: -10%; 
    bottom: 0;
    width: 70%;
    z-index: 0; 
    max-width: 100%;
    height: 80%; 
    display: flex;
    align-items: center;
  }
  
  #banner .image-container img {
    width: 100%; 
    height: 880px; 
    object-fit: cover; 
    object-position: center; 
    border-radius: 10px;
    margin-top: 100px;
  }
  
}