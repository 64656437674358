.footer {
    background-color: #11282b;
    color: #fff;
    padding: 50px 20px;
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .footer-column {
    flex: 1;
    min-width: 250px;
    margin: 20px;
  }
  
  .footer-column h2 {
    color: #36c6d9
  }
  
  .footer-column p, .footer-column ul, .footer-column form {
    margin-top: 10px;
    color: #ccc;
    font-size: 16px;
  line-height: 26px;
  font-weight: 400;
  }
  
  .footer-column ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-column ul li {
    margin: 10px 0;
  }
  
  .footer-column ul li a {
    color: #ccc;
    text-decoration: none;
  }
  
  .footer-column ul li a:hover {
    color: #267883;
  }
  
  .social-icons {
    margin-top: 20px;
  }
  
  .social-icons a {
    color: #36c6d9;
    margin-right: 10px;
    text-decoration: none;
  }
  
  .social-icons a:hover {
    color: #ccc;
  }
  
  .footer-column form {
    display: flex;
    margin-top: 10px;
  }
  
  .footer-column form input {
    padding: 10px;
    border: none;
    border-radius: 5px 0 0 5px;
    flex: 1;
  }
  
  .footer-column form button {
    padding: 10px;
    border: none;
    background-color: #36c6d9;
    color: #11282b;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
  }
  
  .footer-column form button:hover {
    background-color: #267883;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 30px;
  }
  
  .footer-bottom p {
    margin: 10px 0;
    color: #ccc;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
  }
  
  .footer-links a {
    color: #ccc;
    margin: 0 10px;
    text-decoration: none;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
  }
  
  .footer-links a:hover {
    color: #267883;
  }
  #textarea{
    align-content: center;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    .footer-column {
      max-width: 100%;
      text-align: center;
    }
  
    .footer-column form {
      flex-direction: column;
    }
  
    .footer-column form input, .footer-column form button {
      border-radius: 5px;
      margin-top: 10px;
    }
  }
  