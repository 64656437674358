/* src/components/BlogCard.css */
.blog-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    margin: 10px;
    width: 400px;
    transition: transform 0.2s;
  }
  
  .blog-card:hover {
    transform: scale(1.05);
  }
  
  .blog-card img {
    width: 100%;
    height: auto;
  }
  
  .blog-content {
    padding: 20px;
  }
  
  .blog-date {
    font-size: 0.9em;
    color: #999;
  }
  
  .blog-title {
    font-size: 1.2em;
    margin: 10px 0;
  }
  
  .blog-description {
    font-size: 1em;
    color: #555;
  }
  
  .read-more {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 1em;
  }
  
  .read-more:hover {
    background-color: #0056b3;
  }
  

  @media (max-width: 768px) {
    .blog-list {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .blog-card {
      width: 100%;
      margin: 10px 5px; /* Center align and provide vertical margin */
    }
  }
  
  @media (max-width: 480px) {
    .blog-card {
      width: 100%;
      margin: 10px 5px; /* Center align and provide vertical margin */
    }
  }
