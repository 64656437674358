.whatsapp-chat-container {
    position: fixed;
    bottom: 80px;

    right: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 1000;
    background-color: white;
    padding: 10px 15px;
    border-radius: 50px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.15);
    color: #25D366; /* WhatsApp green color */
}

.whatsapp-icon {
    background-color: #25D366;
    border-radius: 50%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.whatsapp-icon .fa-whatsapp {
    color: white;
}

.whatsapp-chat-label {
    font-size: 16px;
    color: #000;
}
