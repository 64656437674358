body {
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

section.services {
  padding: 50px 20px;
  background-color: #fff;
  height: 100vh; 
}

.services-container {
  align-items: center;
  margin: 20px 40px;
}

.services-header {
  margin-right: 20px;
  justify-content: center;
  text-align: center;
  color: #000;
}

.services-header h4 {
  color: #36c6d9;
  font-size: 40px;
  line-height: 48px;
  font-weight: 800;
  margin-bottom: 10px;
}

.services-header h2 {
  font-weight: 800;
  font-size: 30px;
  line-height: 38px;
  color: #11282b;
  margin-bottom: 15px;
}

.services-header p {
  font-size: 16px;
  margin-bottom: 20px;
}

.services-header button {
  background-color: #11282b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.services-slider {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden; 
  height: 400px;
  width: 100%;
}

.left-arrow, .right-arrow {
  position: absolute;
  top: 50%;
  font-size: 2rem;
  color: #000;
  background: none;
  border: none;
  cursor: pointer;
  transform: translateY(-50%);
  z-index: 1;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.slide-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
}

.slide {
  min-width: calc(25% - 20px);
  margin: 0 10px;
}

.service-card {
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: left;
  padding: 20px;
  height: 100%; 
}

.service-card .service-icon img {
  width: 100%;
  height: 250px; 
  margin-bottom: 10px;
}

.service-card h3 {
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

@media (max-width: 768px) {
  .services{
    height: 50vh;
  }
  .services-container {
    flex-direction: column;
    height: auto; 
  }

  .services-header {
    margin-bottom: 20px;
    margin-right: 0;
    text-align: center;
  }

  .services-header h2 {
    font-size: 28px;
  }
  .service-card .service-icon img {
    width: 100%;
    height: 250px; 
    margin-bottom: 10px;
  }

  .services-header p {
    font-size: 14px;
  }

  .left-arrow, .right-arrow {
    font-size: 1.5rem;
  }

  .slide-wrapper {
    flex-direction: row; /* Ensure the slides are in a row */
    overflow-x: scroll; /* Allow horizontal scrolling */
    scroll-snap-type: x mandatory; /* Enable snapping to slides */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  }

  .slide {
    min-width: calc(100% - 0px); /* One slide visible at a time */
    margin: 0 50px;
    scroll-snap-align: center; /* Ensure the slide aligns to the center */
  }
}