
body {
  font-family: "Open Sans", sans-serif;
}
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  height: 100vh;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.experience-section {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px;
  border-radius: 10px;
  margin-bottom: 20px;
  background-image: url('../../image/homepage-img.jpeg');
  background-size: cover;
  background-position: center;
  width: 500px;
  height: 80vh;
  margin-top: 80px;
  color: white;
}

.experience-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.experience-years {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.experience-badge img {
  width:200px;
  height: 200px;
}

.experience-years h1 {
  font-size: 88px;
  line-height: 106px;
  font-weight: 800;
  margin: 10px 0;
}

.experience-years p {
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
}

.about-section {
  text-align: left;
  padding: 20px;
  max-width: 600px;
}

.about-section h2 {
  font-weight: 800;
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 20px;
  color: #11282b;
}
.about-section h3{
  font-size: 40px;
  line-height: 48px;
  font-weight: 800;
  color: #36c6d9;
}

.about-section p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 10px;
  color: rgb(117, 117, 117);
}

.learn-more {
  background-color: #36c6d9;
  color: #11282b;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.learn-more:hover {
  background-color: #14474e;
  color: white;
}

/* Responsive Design */
@media (max-width: 768px) {
  .experience-section {
    height: 60vh;
    width: 90%;
    padding: 20px;
    margin-top: 20px;
  }

  .container {
    flex-direction: column;
    height: auto;
    margin-top: 20px;
  }

  .experience-content {
    flex-direction: column;
  }

  .about-section {
    padding: 10px;
    text-align: center;
    max-width: 90%;
    margin-bottom: 20px;
  }
  .about-section h2 {
    font-weight: 800;
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 20px;
    color: #11282b;
  }
}